import * as React from 'react';
import "./healthChecklist.css";

export type ListProps = {
  title: String,
  items: Array
}

export const List = (props: ListProps) => {

  return (
    <div className="list-container">
      <div className="text-bold-16">{props.title}</div>
      <ul className="list">
        {
          props.items.map((it, index) =>
            <li className="list-item" key={index}>
              <div className="list-dash">-</div>
              <div className="text-regular-16">{it}</div>
            </li>
          )
        }
      </ul>
    </div>
  )
}