import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from "../services/AnalyticsService";
import Logo from "../resources/images/logo.png";
import "../App.css";

export function Home() {
  const { t, i18n } = useTranslation('translation');

  useEffect(() => {
    //analytics heere
    if (process.env.REACT_APP_ENV === "prod") {
      analyticsService().trackPage("websiteVisit", {
        fullUrl: window.location.href,
        pageName: "Home page"
      });
    }
  }, []);

  function onLinkClicked() {
    if (process.env.REACT_APP_ENV === "prod") {
      analyticsService().trackPage("websiteAction", {
        where: window.location.href,
        clicked: "Visit Ready Set Holiday",
        openedPage: "https://www.readysetholiday.co/"
      });
    }
  }

  return (
    <div className="home-main-container">
      <span className="spam-40" />
      <img className="logo" src={Logo}></img>
      <span className="spam-40" />
      <span className="spam-40" />
      <div className="home-text-container">
        {t("enjoyHolidayWith")}
        <div>
          <a className="section-readmore-text"
            onClick={() => onLinkClicked()}
            href="https://www.readysetholiday.co/"
            target="_blank"
            rel="noopener noreferrer">
            {t("readySetHoliday")}
          </a>
          {i18n.language === "hu" ?
            t("withApp")
            : null
          }
        </div>
      </div>
    </div>
  )
}


