import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { NavigationTab } from "../components/navigationTab/NavigationTab";
import { Health } from "./Health";
import { EmptyPage } from "./EmptyPage";
import { CovidManager } from "../manager/CovidManager";
import { FirebaseService } from '../services/FirebaseService';
import Helipad from "../resources/images/helipad.png";

export function TravelSafety() {
  const queryString = require("query-string");
  const { t, i18n } = useTranslation('translation');
  const [covidState, setCovidState] = useState({})
  const [firebaseState, setFirebaseState] = useState({})
  let { path, url } = useRouteMatch();

  useEffect(() => {
    async function fetchData() {
      let regEx = /^5[0-9][0-9]$/
      let iso = getCountryIso();
      let covidManager = new CovidManager();
      let firebaseService = new FirebaseService();

      let covidResponse = covidManager.getCovidDataByCountry(iso)
        .then((resp) => {
          setCovidState({ ...covidState, isFinished: true, data: resp})
        });

      let firebaseResponse = firebaseService.getDataByCountry(iso)
        .then((resp) => {
          setFirebaseState({ ...firebaseState, isFinished: true, data: resp.response })
        });

      //Disease API
      if (regEx.test(covidResponse.statusCode)) {
        window.location.href = `${window.location.origin}/${i18n.language}/error`
      }

      //Firebase
      if (regEx.test(firebaseResponse.statusCode)) {
        window.location.href = `${window.location.origin}/${i18n.language}/error`
      }
    }

    fetchData();
  }, []);

  function getCountryIso() {
    let countryIso = queryString.parse(window.location.search).iso
    if (countryIso)
      window.sessionStorage.setItem("countryIso", countryIso)
    return countryIso ?? window.sessionStorage.getItem("countryIso", countryIso) ?? "";
  }

  function getCountryName() {
    let countryName = queryString.parse(window.location.search).name
    if (countryName)
      window.sessionStorage.setItem("countryName", countryName)
    return countryName ?? window.sessionStorage.getItem("countryName", countryName) ?? undefined;
  }

  return (
    <div className="travelSafety-body">
      <div className="general-title">{t("travelSafety")}</div>
      {getCountryName() ?
        <div className="central-text">
          <span className="spam-15" />
          <div className="text-regular-16">{t("infoForTravelersTo").replace("[COUNTRY_NAME]", getCountryName())}</div>

        </div> : null
      }
      <span className="spam-40" />
      <div>
        <img className="bg-watermark-image" src={Helipad} />
        <NavigationTab url={url} />
        <Switch>
          <Route exact path={path}>
          </Route>
          <Route path={`${path}/:topicId`}>
            <Topic
              isFinishedCovid={covidState.isFinished}
              isFinishedFirebase={firebaseState.isFinished}
              covidData={covidState.data}
              healthData={firebaseState.data} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

function Topic({ isFinishedCovid, covidData, healthData, isFinishedFirebase }) {
  let { topicId } = useParams();
  switch (topicId) {
    case "health":
      return <Health
        isFinishedCovid={isFinishedCovid}
        covidData={covidData}
        healthData={healthData}
        isFinishedFirebase={isFinishedFirebase} />;
    case "goodtoknow":
      return EmptyPage();
    default:
      return <Health
        isFinishedCovid={isFinishedCovid}
        covidData={covidData}
        healthData={healthData}
        isFinishedFirebase={isFinishedFirebase} />;
  }
}

