import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from "../components/section/Section";
import { Covid } from "../components/covid/Covid";
import { HealthChecklist } from "../components/healthChecklist/HealthChecklist"

import Summary from "../resources/images/health-summary.png";
import Covid19 from "../resources/images/health-covid.png";
import Advisory from "../resources/images/health-advisory.png";
import Vaccination from "../resources/images/health-vaccinations.png";

import Insurance from "../resources/images/health-insurance.png";
import { CovidStatsForCountry } from "../models/CovidStatsForCountry";
import { HealthModel } from "../models/HealthModel";
import { analyticsService } from "../services/AnalyticsService";

/**
 * Health tab
 * @param covidData = Data from the Covid API
 * @param data = Data fetched from the Firebase database
 */
export function Health({ isFinishedCovid, covidData, healthData, isFinishedFirebase }) {
  const { t, i18n } = useTranslation('translation');
  let lang = i18n.language.length > 2 ? "en" : i18n.language;

  useEffect(() => {
    //analytics here
    if (process.env.REACT_APP_ENV === "prod") {
      analyticsService().trackPage("websiteVisit", {
        fullUrl: window.location.href,
        pageName: "TravelSafety page - Health tab"
      });
    }
  }, []);

  let _healthData = new HealthModel(healthData, t);
  let _covidData = new CovidStatsForCountry(covidData && covidData.payload || {}, lang)

  return (
    <div>
      <span className="spam-40" />
      <Section
        image={Advisory}
        title={t("advisory")}
        withReadMore={true}
        text={_healthData.advisoryText}
        readMoreUrl={_healthData.advisoryUrl}
        loadingSpinnerVisibility={!isFinishedFirebase}
      />
      <span className="spam-40" />
      <Covid
        image={Covid19}
        title={t("covid19")}
        data={_covidData}
        isLoading={!isFinishedCovid}
      />
      <HealthChecklist />
    </div>
  )
}


