export const es = {
  travelSafety: "Seguridad de viaje",
  health: "Salud",
  remember: "Recuerda",
  summary: "Resumen",
  vaccination: "Vacunación",
  advisory: "Consultivo",
  readMore: "Lee mas.",
  covid19: "COVID-19",
  updated: "Actualizado",
  total: "Total",
  yesterday: "Ayer",
  cases: "Casos",
  deaths: "Fallecidos",
  recovered: "Recuperado",
  casesPerMillion: "Casos por millón",
  testsPerMillion: "Puebas por millón",
  population: "Población",
  checklist: "Lista de Verificación",
  insurance: "Seguro",
  importInApp: "Importar en la aplicación",
  advisoryDescription: "Es posible que se apliquen restricciones de viaje debido a la pandemia de COVID-19.",
  noCovidData: "No hay datos disponibles en este momento.",
  infoForTravelersTo: "Información para personas que viajen al siguiente país: [COUNTRY_NAME].",
  enjoyHolidayWith: "Disfruta tus vacaciones con ",
  readySetHoliday: "Ready Set Holiday!",
  serverErrorText: "Lo sentimos, hubo un error al acceder a esta información.",
  notFoundText: "Lo sentimos, no se encontró la información solicitada.",
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}