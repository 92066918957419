import * as React from "react";
import { useTranslation } from 'react-i18next';
import { SectionTitle } from "../section/SectionTitle";
import { SectionText } from "../section/SectionText";
import "./covid.css";
import { CovidStatsForCountry } from "../../models/CovidStatsForCountry";
import { CovidDetails } from "./CovidDetails";
import { Spinner } from "../spinner/Spinner";

export type CovidProps = {
  title: String,
  image: any,
  updatedAt: String,
  casesModel: String,
  data: CovidStatsForCountry,
  placeholderText: String,
  isLoading: Boolean
}

export const Covid = (props: CovidProps) => {
  const { t, i18n } = useTranslation('translation');

  return (
    <div>
      <div className="covid-title-line">
        <SectionTitle
          image={props.image}
          title={props.title}
        />
        {props.data.country ?
          <div className="covid-updatedat-text">{t("updated") + ": " + props.data.updated}</div> : null}
      </div>

      {props.isLoading ?
        //data is still loading, should show spinner
        <div className="section">
          <Spinner />
        </div>
        : props.data.country ?
          <div>
            <div className="covid-details">
              <div className="covid-details-column">
                <div className="text-bold-16">{t("yesterday")}</div>
                <CovidDetails
                  valueName={t("cases")}
                  value={props.data.todayCases}
                />
                <CovidDetails
                  valueName={t("deaths")}
                  value={props.data.todayDeaths}
                />
              </div>

              <div className="covid-details-column">
                <div className="text-bold-16">{t("total")}</div>
                <CovidDetails
                  valueName={t("cases")}
                  value={props.data.totalCases}
                />
                <CovidDetails
                  valueName={t("deaths")}
                  value={props.data.totalDeaths}
                />
                <CovidDetails
                  valueName={t("recovered")}
                  value={props.data.totalRecovered}
                />
              </div>
            </div>
            <div className="covid-details-column" style={{ alignItems: "end" }}>
              <CovidDetails
                valueName={t("casesPerMillion")}
                value={props.data.casesPerOneMillion}
              />
              <CovidDetails
                valueName={t("testsPerMillion")}
                value={props.data.testsPerOneMillion}
              />
              <CovidDetails
                valueName={t("population")}
                value={props.data.population}
              />
            </div>
          </div>
          :
          <SectionText
            text={t("noCovidData")}
          />
      }
    </div>
  )
}
