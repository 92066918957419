import "./covid.css"

type CovidDetailsProps = {
  valueName: String,
  value: String
}
export const CovidDetails = (props: CovidDetailsProps) => {
  return (
    <div className="covid-extradetails-row">
      <div className="text-regular-16">{props.valueName}: </div>
      <div className="text-regular-16">{props.value}</div>
    </div>
  )
}