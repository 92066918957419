import { en_healthChecklist } from "./en_healthChecklist";

export const en = {
  travelSafety: "Travel Safety",
  health: "Health",
  remember: "Remember",
  summary: "Summary",
  vaccination: "Vaccination",
  advisory: "Advisory",
  readMore: "Read more.",
  covid19: "COVID-19",
  updated: "Updated",
  total: "Total",
  yesterday: "Yesterday",
  cases: "Cases",
  deaths: "Deaths",
  recovered: "Recovered",
  casesPerMillion: "Cases per million",
  testsPerMillion: "Tests per million",
  population: "Population",
  checklist: "Checklist",
  insurance: "Insurance",
  importInApp: "Import in app",
  advisoryDescription: "Travel restrictions may apply due to the COVID-19 pandemic.",
  noCovidData: "No data available at this time.",
  infoForTravelersTo: "Information for people traveling to [COUNTRY_NAME].",
  enjoyHolidayWith: "Enjoy your holiday with ",
  readySetHoliday: "Ready Set Holiday!",
  serverErrorText: "We are sorry, there was an error accessing this information.",
  notFoundText: "We are sorry, the information you requested was not found.",
  healthChecklist: en_healthChecklist,
  
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}