import i18n from 'i18next'

import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'

import {en as English} from "./en/en";
import {de as Deutch} from "./de/de";
import {es as Spanish} from "./es/es";
import {fr as French} from "./fr/fr";
import {hu as Hungarian} from "./hu/hu";
import {it as Italian} from "./it/it";
import {nl as Dutch} from "./nl/nl";
import {pt as Portuguese} from "./pt/pt";
import {ro as Romanian} from "./ro/ro";
import {zh as Chinese} from "./zh/zh";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: English
      },
      de: {
        translation: Deutch
      },
      es: {
        translation: Spanish
      },
      fr: {
        translation: French
      },
      hu: {
        translation: Hungarian
      },
      it: {
        translation: Italian
      },
      nl: {
        translation: Dutch
      },
      pt: {
        translation: Portuguese
      },
      ro: {
        translation: Romanian
      },
      zh: {
        translation: Chinese
      }
    },
    fallbackLng: ['en'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: '.'
    }
  })

export default i18n
