
import firebase from '../firebase';

export function analyticsService() {
  return {
    trackPage: (eventName, details) => {
      if (process.env.REACT_APP_ENV === "prod") {
        firebase.analytics().logEvent(eventName, details);
      }
    }
  };
}