import axios from "axios"

export class FetchService {
 
  constructor() {
    //for headers in the future
  }

  async get(url) {
    try {
      const response = await axios.get(url)
      return {
        payload: response.data,
        error: null,
        statusCode: "200"
      }
    } catch (err) {
      return {
        payload: null,
        error: err?.message,
        statusCode: err?.response?.status
      }
    }
  }

  //put
  //post
  //delete
}
