import * as React from 'react';
import { useTranslation } from 'react-i18next';
import "./healthChecklist.css";
import { Section } from "../section/Section"
import { List } from "./List"

import Checklist from "../../resources/images/health-checklist.png";

export type HealthChecklistProps = {
}

export const HealthChecklist = (props: HealthChecklistProps) => {
  const { t, i18n } = useTranslation('translation');
  let lang = i18n.language.length > 2 ? "en" : i18n.language;

  const documents = [
    t("healthChecklist.txtDoc1"),
    t("healthChecklist.txtDoc2"),
    t("healthChecklist.txtDoc3"),
    t("healthChecklist.txtDoc4"),
    t("healthChecklist.txtDoc5")
  ]
  const medicines = [
    t("healthChecklist.txtMed1"),
    t("healthChecklist.txtMed2"),
    t("healthChecklist.txtMed3"),
    t("healthChecklist.txtMed4"),
    t("healthChecklist.txtMed5"),
    t("healthChecklist.txtMed6"),
    t("healthChecklist.txtMed7"),
    t("healthChecklist.txtMed8"),
    t("healthChecklist.txtMed9")
  ]
  const prescriptions = [
    t("healthChecklist.txtPrescript1"),
    t("healthChecklist.txtPrescript2"),
    t("healthChecklist.txtPrescript3"),
  ]
  const prevention = [
    t("healthChecklist.txtPrev1"),
    t("healthChecklist.txtPrev2"),
    t("healthChecklist.txtPrev3"),
    t("healthChecklist.txtPrev4"),
    t("healthChecklist.txtPrev5")
  ]
  const firstAidKit = [
    t("healthChecklist.txtKit1"),
    t("healthChecklist.txtKit2"),
    t("healthChecklist.txtKit3"),
    t("healthChecklist.txtKit4")
  ]
  const supply = [
    t("healthChecklist.txtSupply1"),
    t("healthChecklist.txtSupply2"),
    t("healthChecklist.txtSupply3"),
  ]

  if (lang === "en" || lang === "de") {
    return (
      <div>
        <span className="spam-40" />
        <Section
          image={Checklist}
          title={t("healthChecklist.healthChecklistTitle")}
          text={t("healthChecklist.healthChecklistIntro1")}
          text2={t("healthChecklist.healthChecklistIntro2")}
        />
        <List
          title={t("healthChecklist.headerDocuments")}
          items={documents}
        />
        <List
          title={t("healthChecklist.headerMedicines")}
          items={medicines}
        />
        <List
          title={t("healthChecklist.headerPrescriptions")}
          items={prescriptions}
        />
        <List
          title={t("healthChecklist.headerPrevention")}
          items={prevention}
        />
        <List
          title={t("healthChecklist.headerFirstAidKit")}
          items={firstAidKit}
        />
        <List
          title={t("healthChecklist.headerSupplies")}
          items={supply}
        />
      </div>
    )
  }
  else {
    return (null)
  }
}