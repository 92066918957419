import { de_healthChecklist } from "./de_healthChecklist";

export const de = {
  travelSafety: "Reisesicherheit",
  health: "Gesundheit",
  remember: "Merken",
  summary: "Zusammenfassung",
  vaccination: "Impfung",
  advisory: "Beratend",
  readMore: "Weiterlesen.",
  covid19: "COVID-19",
  updated: "Aktualisiert",
  total: "Gesamt",
  yesterday: "Gestern",
  cases: "Fälle",
  deaths: "Todesfälle",
  recovered: "Genesen",
  casesPerMillion: "Fälle pro Million",
  testsPerMillion: "Tests pro Million",
  population: "Bevölkerung",
  checklist: "Checkliste",
  insurance: "Versicherung",
  importInApp: "In App importieren",
  advisoryDescription: "Aufgrund der COVID-19-Pandemie können Reisebeschränkungen gelten.",
  noCovidData: "Derzeit sind keine Daten verfügbar.",
  infoForTravelersTo: "Informationen für Personen, die nach [COUNTRY_NAME] reisen.",
  enjoyHolidayWith: "Genießen Sie Ihren Urlaub mit ",
  readySetHoliday: "Fertig Los Urlaub!",
  serverErrorText: "Es tut uns leid, es ist ein Fehler beim Zugriff auf diese Informationen aufgetreten.",
  notFoundText: "Es tut uns leid, die von Ihnen angeforderten Informationen wurden nicht gefunden.",
  healthChecklist: de_healthChecklist,

  testText: "Deutsches Ipsum Dolor id latine Mesut Özil complectitur pri, mea meliore denique bitte id. Elitr expetenda nam an, Eichhörnchen ei reque euismod assentior. Odio Frau Professor iracundia ex pri. Ut vel Prost mandamus, quas natum adversarium ei bitte diam minim honestatis eum no"
}