export const zh = {
  travelSafety: "旅行安全",
  health: "健康",
  remember: "记得",
  summary: "概要",
  vaccination: "预防接种",
  advisory: "咨询",
  readMore: "阅读更多。",
  covid19: "新冠肺炎",
  updated: "更新",
  total: "总",
  yesterday: "昨天",
  cases: "案例",
  deaths: "死亡人数",
  recovered: "已恢复",
  casesPerMillion: "百万例",
  testsPerMillion: "每百万测试",
  population: "人口",
  checklist: "检查清单",
  insurance: "保险",
  importInApp: "导入应用",
  advisoryDescription: "由于COVID-19大流行，旅行限制可能适用。",
  noCovidData: "目前没有可用数据。",
  infoForTravelersTo: "前往以下国家/地区的人们的信息：[COUNTRY_NAME]",
  enjoyHolidayWith: "享受您的假期 ",
  readySetHoliday: "度假万事备",
  serverErrorText: "很抱歉，访问此信息时出错。",
  notFoundText: "很抱歉，找不到您要求的信息。",
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}