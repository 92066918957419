

export class HealthModel {
  advisoryText = "";
  advisoryUrl = null;

  constructor(response, translator) {
    this.advisoryText = response ? response.advisoryCovidText ?? translator("advisoryDescription") : translator("noCovidData");
    this.advisoryUrl = response?.advisoryCovidURL ?? null;
  }

}