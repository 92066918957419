
export class CovidStatsForCountry {
  updated = "";
  country = null;
  totalCases = "";
  totalDeaths = "";
  totalRecovered = "";
  todayCases = "";
  todayDeaths = "";
  todayRecovered = "";
  testsPerOneMillion = "";
  casesPerOneMillion = "";
  population = "";

  constructor(response, lang) {
    this.updated = this.convertToHumanReadable(response.updated, lang);
    this.country = response.country;
    this.totalCases = this.formatNumbers(response.cases, lang);
    this.totalDeaths = this.formatNumbers(response.deaths, lang);
    this.totalRecovered = this.formatNumbers(response.recovered, lang);

    this.todayCases = this.formatNumbers(response.todayCases, lang);
    this.todayDeaths = this.formatNumbers(response.todayDeaths, lang);
    this.todayRecovered = this.formatNumbers(response.todayRecovered, lang);

    this.testsPerOneMillion = this.formatNumbers(response.testsPerOneMillion, lang);
    this.casesPerOneMillion = this.formatNumbers(response.casesPerOneMillion, lang);
    this.population = this.formatNumbers(response.population, lang);
  }

  convertToHumanReadable(ts, lang) {
    let date = new Date(ts);
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    };
    let localeDate = date.toLocaleDateString(lang, options);
    return localeDate;
  }

  formatNumbers(nr, lang) {
    let formatted = nr && nr.toLocaleString(lang);
    return formatted;
  }
}