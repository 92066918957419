export const ro = {
  travelSafety: "Siguranța călătoriei",
  health: "Sănătate",
  remember: "Ține minte",
  summary: "Rezumat",
  vaccination: "Vaccinare",
  advisory: "Consultativ",
  readMore: "Citește mai mult.",
  covid19: "COVID-19",
  updated: "Actualizat",
  total: "Total",
  yesterday: "Ieri",
  cases: "Cazuri",
  deaths: "Decese",
  recovered: "Recuperări",
  casesPerMillion: "Cazuri pe milion",
  testsPerMillion: "Teste pe milion",
  population: "Populația",
  checklist: "Listă",
  insurance: "Asigurare",
  importInApp: "Importați în aplicație",
  advisoryDescription: "Se pot aplica restricții de călătorie din cauza pandemiei COVID-19.",
  noCovidData: "Nu există date disponibile în acest moment.",
  infoForTravelersTo: "Informații pentru persoanele care călătoresc în [COUNTRY_NAME]",
  enjoyHolidayWith: "Bucurați-vă de vacanță cu ",
  readySetHoliday: "Pe locuri, fiți gata, concediu!",
  serverErrorText: "Ne pare rău, a apărut o eroare la accesarea acestor informații.",
  notFoundText: "Ne pare rău, informațiile pe care le-ați solicitat nu au fost găsite.",
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}