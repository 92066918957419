export const pt = {
  travelSafety: "Segurança em viagens",
  health: "Saúde",
  remember: "Lembrar",
  summary: "Resumo",
  vaccination: "Vacinação",
  advisory: "Consultivo",
  readMore: "Consulte Mais informação.",
  covid19: "COVID-19",
  updated: "Atualizada",
  total: "Total",
  yesterday: "Ontem",
  cases: "Casos",
  deaths: "Mortes",
  recovered: "Recuperado",
  casesPerMillion: "Casos por milhão",
  testsPerMillion: "Testes por milhão",
  population: "População",
  checklist: "Lista de verificação",
  insurance: "Seguro",
  importInApp: "Importar no aplicativo",
  advisoryDescription: "Restrições de viagens podem ser aplicadas devido à pandemia COVID-19.",
  noCovidData: "Nenhum dado disponível no momento.",
  infoForTravelersTo: "Informações para pessoas que viajam para o seguinte país: [COUNTRY_NAME]",
  enjoyHolidayWith: "Aproveite suas férias com ",
  readySetHoliday: "Aos seus lugares, prontos? Férias!",
  serverErrorText: "Lamentamos, ocorreu um erro ao acessar essas informações.",
  notFoundText: "Lamentamos, a informação solicitada não foi encontrada.",
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}