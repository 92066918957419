import * as React from "react";
import "./section.css";
import { SectionTitle } from "./SectionTitle"
import { SectionText } from "./SectionText"
import { Spinner } from "../spinner/Spinner";

export type SectionProps = {
  title: String,
  image: any,
  text: String,
  text2: String,
  withReadMore: boolean,
  readMoreUrl: String,
  loadingSpinnerVisibility: boolean
}

export const Section = (props: SectionProps) => {

  return (
    <div className="section">
      <SectionTitle
        title={props.title}
        image={props.image}
      />
      {props.withReadMore ?
        props.loadingSpinnerVisibility ? <Spinner /> :
          <SectionText
            text={props.text}
            readMoreUrl={props.readMoreUrl}
          />
        : null
      }
      {
        props.text2 ?
          <SectionText
            text={props.text2}
            readMoreUrl={props.readMoreUrl}
          />
          : null
      }
    </div>
  )
}