import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import restricted from './Restricted';
import { TravelSafety } from "./pages/TravelSafety";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { ServerError } from "./pages/ServerError";
import { useTranslation } from 'react-i18next';


function App() {
  const { t, i18n } = useTranslation('translation');
  const baseRouteUrl = "/:locale(en|de|es|fr|hu|it|nl|pt|ro|zh)?";
  const baseUrl = i18n.language === 'en' ? '' : '/' + i18n.language;

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path={baseRouteUrl + "/"} component={Home} />
          <Route exact path={baseRouteUrl + "/error"} component={ServerError} />
          <Route path={baseRouteUrl + "/travelsafety"} component={restricted(TravelSafety)} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
