export const it = {
  travelSafety: "Sicurezza in viaggio",
  health: "Salute",
  remember: "Ricorda",
  summary: "Sommario",
  vaccination: "Vaccinazione",
  advisory: "Consultivo",
  readMore: "Leggi di più.",
  covid19: "COVID-19",
  updated: "Aggiornato",
  total: "Totale",
  yesterday: "Ieri",
  cases: "Casi",
  deaths: "Deceduti",
  recovered: "Recuperato",
  casesPerMillion: "Casi per milione",
  testsPerMillion: "Test per milione",
  population: "Popolazione",
  checklist: "Lista di controllo",
  insurance: "Assicurazione",
  importInApp: "Importa nell'app",
  advisoryDescription: "Potrebbero essere applicate restrizioni di viaggio a causa della pandemia COVID-19.",
  noCovidData: "Nessun dato disponibile in questo momento.",
  infoForTravelersTo: "Informazioni per chi si reca nel seguente paese: [COUNTRY_NAME].",
  enjoyHolidayWith: "Goditi la tua vacanza con ",
  readySetHoliday: "Pronti, partenza, vacanza",
  serverErrorText: "Siamo spiacenti, si è verificato un errore durante l'accesso a queste informazioni.",
  notFoundText: "Siamo spiacenti, le informazioni richieste non sono state trovate.",
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}