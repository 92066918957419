export const en_healthChecklist = {
  healthChecklistTitle: "Medical Kit",
  healthChecklistIntro1: "Packing a medical kit can help you avoid wasted time, inconveniences or worse. Think about the activities you have planned and the area you are visiting: what may or may not be available there.",
  healthChecklistIntro2: "This is only a guide to things you might need when travelling. Check with your doctor for advice for your specific medical history and travel plans.",
  headerDocuments: "Documents",
  txtDoc1: "Copies of your passport and travel documents",
  txtDoc2: "Copies of all prescriptions (e.g. medications, glasses, medical supplies)",
  txtDoc3: "Health insurance card or documents",
  txtDoc4: "If you have health conditions that may require emergency care, such as diabetes or serious allergies, carry a card or wear a bracelet",
  txtDoc5: "Contact card for a close family member and your doctor",
  headerMedicines: "Medicines",
  txtMed1: "Antihistamine tablets for bites, stings or allergies",
  txtMed2: "Pain / fever reliever (e.g. ibuprofen, paracetamol, aspirin)",
  txtMed3: "Diarrhoea medicine (e.g. Imodium)",
  txtMed4: "Fluid and electrolyte replacement powder or tablets",
  txtMed5: "Antacid for heartburn / indigestion",
  txtMed6: "Cold and cough remedies",
  txtMed7: "Motion sickness tablets",
  txtMed8: "Mild laxative, for constipation",
  txtMed9: "Anti-fungal or antibacterial cream",
  headerPrescriptions: "Prescriptions",
  txtPrescript1: "Regular prescription medicines, kept in their original containers and carried in your hand luggage.",
  txtPrescript2: "Take a signed letter from your doctor about them. Some countries may not let visitors bring in certain medicines, so check this in advance, follow the Advisory link above.",
  txtPrescript3: "Travelling to developing countries, remote areas, tropical climates and high altitudes might increases your risk of certain conditions (e.g. malaria), needing specific prescription medicines, talk with your doctor about this.",
  headerPrevention: "Prevention",
  txtPrev1: "Ear plugs",
  txtPrev2: "Sunscreen (at least SPF 30+) and aloe vera gel for sunburns",
  txtPrev3: "Eye lubricant drops",
  txtPrev4: "Insect repellent",
  txtPrev5: "Water purifying tablets",
  headerFirstAidKit: "First-aid kit",
  txtKit1: "Hand sanitiser or wipes",
  txtKit2: "Antiseptic solution or ointment for cleaning wounds or bites",
  txtKit3: "Band-aids",
  txtKit4: "Thermometer (a no-batteries forehead thermometer strip is best for travel)",
  txtKit5: "First aid reference",
  headerSupplies: "Supplies",
  txtSupply1: "Spare pair of glasses, contact lens + solution",
  txtSupply2: "Medical equipment you use regularly, such as sterile syringes and needles",
  txtSupply3: "Condoms, birth control pills, or other contraceptives if needed"
}