import * as React from "react";
import "./section.css";
import { useTranslation } from 'react-i18next';
import { analyticsService } from "../../services/AnalyticsService";

export type SectionTextProps = {
  text: String,
  readMoreUrl: String
}

export const SectionText = (props: SectionTextProps) => {
  const { t, i18n } = useTranslation('translation');

  function onLinkClicked() {
    if (process.env.REACT_APP_ENV === "prod") {
      analyticsService().trackPage("websiteAction", {
        where: window.location.href,
        clicked: "Read more",
        openedPage: props.readMoreUrl
      });
    }
  }

  return (
    <div className="sectionText-main">
      {props.text + " "}
      {props.readMoreUrl ?
        <a className="section-readmore-text"
          onClick={() => onLinkClicked()}
          href={props.readMoreUrl}
          target="_blank"
          rel="noopener noreferrer">{t("readMore")}
        </a>
        : null}
    </div>
  )
}