
export const de_healthChecklist = {
  healthChecklistTitle: "Reisegesundheitsset",
  healthChecklistIntro1: "Das Packen eines Reisegesundheitssets kann Ihnen helfen, Zeitverschwendung, Unannehmlichkeiten oder Schlimmeres zu vermeiden. Denken Sie an die geplanten Aktivitäten und das Gebiet, das Sie besuchen: was dort möglicherweise verfügbar ist oder nicht.",
  healthChecklistIntro2: "Dies ist nur eine Anleitung zu Dingen, die Sie möglicherweise auf Reisen benötigen. Fragen Sie Ihren Arzt nach Ratschlägen zu Ihrer Krankengeschichte und Ihren Reiseplänen.",
  headerDocuments: "Unterlagen",
  txtDoc1: "Kopien Ihres Reisepasses und Ihrer Reisedokumente ",
  txtDoc2: "Kopien aller Rezepte (z. B. Medikamente, Brillen, medizinische Versorgung)",
  txtDoc3: "Krankenversicherungskarte oder Dokumente",
  txtDoc4: "Wenn Sie gesundheitliche Probleme haben, die eine Notfallversorgung erfordern, wie Diabetes oder schwere Allergien, tragen Sie eine Karte oder ein Armband",
  txtDoc5: "Kontaktkarte für ein nahes Familienmitglied und Ihren Arzt",
  headerMedicines: "Medikamente",
  txtMed1: "Antihistaminika gegen Bisse, Stiche oder Allergien",
  txtMed2: "Schmerz- / Fiebermittel (z. B. Ibuprofen, Paracetamol, Aspirin)",
  txtMed3: "Durchfallmedizin (z.B. Imodium)",
  txtMed4: "Ersatzpulver oder -tabletten für Flüssigkeiten und Elektrolyte",
  txtMed5: "Antazida gegen Sodbrennen / Verdauungsstörungen",
  txtMed6: "Erkältungs- und Hustenmittel",
  txtMed7: "Reisekrankheitstabletten",
  txtMed8: "Mildes Abführmittel gegen VerstopfungMildes Abführmittel gegen Verstopfung",
  txtMed9: "Antimykotische oder antibakterielle Creme",
  headerPrescriptions: "Rezepte",
  txtPrescript1: "Regelmäßige verschreibungspflichtige Medikamente, die in ihren Originalbehältern aufbewahrt und im Handgepäck mitgeführt werden.",
  txtPrescript2: "Nehmen Sie einen unterschriebenen Brief von Ihrem Arzt darüber. In einigen Ländern dürfen Besucher bestimmte Medikamente möglicherweise nicht mitbringen. Überprüfen Sie dies daher im Voraus. Folgen Sie dem obigen Link \"Hinweise\".",
  txtPrescript3: "Reisen in Entwicklungsländer, abgelegene Gebiete, tropische Klimazonen und Höhenlagen können das Risiko für bestimmte Erkrankungen (z. B. Malaria) erhöhen, wenn Sie bestimmte verschreibungspflichtige Medikamente benötigen. Sprechen Sie mit Ihrem Arzt darüber.",
  headerPrevention: "Verhütung",
  txtPrev1: "Ohrstöpsel",
  txtPrev2: "Sonnenschutzmittel (mindestens SPF 30+) und Aloe Vera Gel gegen Sonnenbrand",
  txtPrev3: "Augenschmiermitteltropfen",
  txtPrev4: "Insektenschutz",
  txtPrev5: "Wasserreinigungstabletten",
  headerFirstAidKit: "Erste-Hilfe-Kasten",
  txtKit1: "Händedesinfektionsmittel oder Tücher",
  txtKit2: "Antiseptische Lösung oder Salbe zur Reinigung von Wunden oder Bissen",
  txtKit3: "Pflaster",
  txtKit4: "Thermometer (ein batterieloser Stirnthermometerstreifen eignet sich am besten für unterwegs)",
  txtKit5: "Erste-Hilfe-Referenz",
  headerSupplies: "Lieferungen",
  txtSupply1: "Ersatzbrille, Kontaktlinse + Lösung",
  txtSupply2: "Medizinische Geräte, die Sie regelmäßig verwenden, wie sterile Spritzen und Nadeln",
  txtSupply3: "Bei Bedarf Kondome, Antibabypillen oder andere Verhütungsmittel"
}