export const nl = {
  travelSafety: "Reisveiligheid",
  health: "Gezondheid",
  remember: "Onthouden",
  summary: "Overzicht",
  vaccination: "Vaccinatie",
  advisory: "Advies",
  readMore: "Lees verder.",
  covid19: "COVID-19",
  updated: "Bijgewerkt",
  total: "Totaal",
  yesterday: "Gisteren",
  cases: "Gevallen",
  deaths: "Sterfgevallen",
  recovered: "Hersteld",
  casesPerMillion: "Gevallen per miljoen",
  testsPerMillion: "Tests per miljoen",
  population: "Bevolking",
  checklist: "Checklist",
  insurance: "Verzekering",
  importInApp: "Importeer in app",
  advisoryDescription: "Vanwege de COVID-19 pandemie kunnen reisbeperkingen van toepassing zijn.",
  noCovidData: "Er zijn momenteel geen gegevens beschikbaar.",
  infoForTravelersTo: "Informatie voor mensen die naar [COUNTRY_NAME] reizen ",
  enjoyHolidayWith: "Geniet van uw vakantie met ",
  readySetHoliday: "Ready Set Holiday! Versie",
  serverErrorText: "Het spijt ons, er is een fout opgetreden bij het openen van deze informatie.",
  notFoundText: "Het spijt ons, maar de gevraagde informatie is niet gevonden.",
  testText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
}