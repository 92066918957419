import * as React from "react";
import { useTranslation } from 'react-i18next';
import "./navigation.css";
import { Tab } from "./Tab";

export type NavigationTabProps = {
  url: String
}

export const NavigationTab = (props: NavigationTabProps) => {
  const { t, i18n } = useTranslation('translation');

  return (
    <nav className="nav-tab-main">
      <Tab
        isEnabled={true}
        name={t("health")}
        url={props.url}
        routeName={"health"} />
      { //Second tab in the future
        // <Tab
        // isEnabled={false}
        // name={t("remember")}
        // url={props.url}
        // routeName={"goodtoknow"} />
      }
    </nav>
  )
}

