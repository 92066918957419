import * as React from "react";
import "./section.css";

export type SectionTitleProps = {
  title: String,
  image: any
}

export const SectionTitle = (props: SectionTitleProps) => {
  const testIconUrl = "https://lh3.googleusercontent.com/proxy/2e3ueszhoh5dHgG-NcA9bI-P1DQxY5ssViI60E3AJmnoZR2jFzS1fbpqrq7_lbO_LReMuveHUcA2n8j_osO-CESF4BBjSdo"
  return (
    <div className="sectionTitle-main">
      <img className="sectionTitle-image" src={props.image}></img>
      <div className="sectionTitle-text">{props.title}</div>
    </div>
  )
}