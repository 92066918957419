import { FetchService } from "../services/FetchService";

export class CovidManager {
  baseUrl = "https://disease.sh/v3/covid-19/";

  async getCovidDataByCountry(countryIso) {
    let url = this.baseUrl + `countries/${countryIso}?yesterday=true&strict=true`
    let fetchService = new FetchService();
    const response = await fetchService.get(url);
    return response;
  }
}