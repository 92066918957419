import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FirebaseService } from "./services/FirebaseService";

const queryString = require("query-string");

const token = "pwd" //todo should update this token

export default (BaseComponent) => {
  class Restricted extends Component {

    async componentWillMount() {
      let queryToken = queryString.parse(window.location.search).token
      this.authenticationCheck(this.props, queryToken);
    }

    async componentWillReceiveProps(nextProps) {
      if (nextProps.location !== this.props.location) {
        let queryToken = queryString.parse(window.location.search).token
        this.authenticationCheck(nextProps, queryToken);
      }
    }

    async authenticationCheck(props, _token) {
      if (window.sessionStorage.getItem("loggedIn") === "true") {
        return
      }
      if (process.env.REACT_APP_ENV === "dev") {
        if (_token !== token) {
          props.history.push('/');
        } else {
          window.sessionStorage.setItem("loggedIn", "true")
        }
      } else if (process.env.REACT_APP_ENV === "prod") {
        let firebaseService = new FirebaseService();
        let isauth = await firebaseService.verifyTokenExistence(_token)
        if (isauth.response) {
          await firebaseService.consumeToken(_token);
          window.sessionStorage.setItem("loggedIn", "true")
        } else {
          props.history.push('/');
        }
      }
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  Restricted.propTypes = {
    location: PropTypes.object.isRequired
  };

  return Restricted;
};