import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsService } from "../services/AnalyticsService";
import Logo from "../resources/images/logo.png";
import "../App.css";

export function NotFound() {
  const { t, i18n } = useTranslation('translation');

  useEffect(() => {
    //analytics here
    if (process.env.REACT_APP_ENV === "prod") {
      analyticsService().trackPage("websiteVisit", {
        fullUrl: window.location.href,
        pageName: "404 Not Found page"
      });
    }
  }, []);

  return (
    <div className="home-main-container">
      <span className="spam-40" />
      <img className="logo" src={Logo}></img>
      <span className="spam-40" />
      <span className="spam-40" />
      <div className="text-regular-16" style={{"text-align": "center"}}>
        {t("notFoundText")}
      </div>
    </div>
  )
}